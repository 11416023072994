import { useEffect, useRef } from 'react'
import type { TFunction } from 'i18next'
import { ArgsProps } from 'antd/es/notification/interface'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

const commonNotificationSettings: Omit<ArgsProps, 'message'> = {
	style: {
		width: 500
	},
	placement: 'bottom',
	duration: 0
}

const NOTIFICATION_STATUS_DURATION = 3

// NOTE: both notifications have the same key, so the previous one is replaced by the new one (prevent for flickering problem during destroying one notification type)
enum NOTIFICATION_KEY {
	STATUS_NOTIFICATION = 'STATUS_NOTIFICATION'
}

const notificationOfflineConfig = (t: TFunction): ArgsProps => ({
	...commonNotificationSettings,
	key: NOTIFICATION_KEY.STATUS_NOTIFICATION,
	className: 'noti-offline-notification',
	message: t('loc:Chyba pripojenia'),
	description: t('loc:Vyzerá to, že ste offline. Skontrolujte si prosím vaše internetové pripojenie.')
})

const notificationBackOnlineConfig = (t: TFunction): ArgsProps => ({
	...commonNotificationSettings,
	key: NOTIFICATION_KEY.STATUS_NOTIFICATION,
	className: 'noti-back-online-notification',
	message: t('loc:Pripojenie obnovené'),
	description: t('loc:Vitajte naspäť online.'),
	duration: NOTIFICATION_STATUS_DURATION
})

/**
 * Hook that listens for network status changes and shows notifications when the network status changes.
 * It shows a notification when the user goes offline and another one when the user goes back online.
 * https://medium.com/@abdulahad2024/real-time-network-status-detection-in-react-js-next-js-67595c4bd81c
 */

const useNetworkStatus = () => {
	const [t] = useTranslation()
	const wasOffline = useRef(false)

	useEffect(() => {
		const updateNetworkStatus = () => {
			if (!navigator.onLine) {
				notification.error(notificationOfflineConfig(t))
			} else if (wasOffline.current) {
				notification.success(notificationBackOnlineConfig(t))
			}
		}

		const handleOffline = () => {
			wasOffline.current = true

			updateNetworkStatus()
		}

		window.addEventListener('load', updateNetworkStatus)
		window.addEventListener('online', updateNetworkStatus)
		window.addEventListener('offline', handleOffline)

		return () => {
			window.removeEventListener('load', updateNetworkStatus)
			window.removeEventListener('online', updateNetworkStatus)
			window.addEventListener('offline', handleOffline)
		}
	}, [t])
}

export default useNetworkStatus
