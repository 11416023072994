import React, { FC } from 'react'
import { Field, getFormValues, InjectedFormProps, isPristine, isSubmitting, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Button, Col, Form, FormProps, Row, Spin } from 'antd'
import { useSelector } from 'react-redux'

// utils
import { BACKGROUND_TYPES, FORM, UPLOAD, UPLOAD_IMG_CATEGORIES, URL_UPLOAD_FILE } from '../../../utils/enums'
import { getUploadLimitsMessage, showErrorNotification } from '../../../utils/helper'

// types
import { IBannerForm } from '../../../types/interfaces'
import { RootState } from '../../../reducers'

// atoms
import InputField from '../../../atoms/InputField'
import ColorPickerField from '../../../atoms/ColorPickerField'
import TextareaField from '../../../atoms/TextareaField'
import ImgUploadField from '../../../atoms/ImgUploadField'
import RadioGroupField from '../../../atoms/RadioGroupField'

// validation
import { validationBannerFn } from '../../../utils/formValidation'

type ComponentProps = FormProps & {
	bannerID?: string
	loadingBannerData?: boolean
}

const formName = FORM.BANNER_FORM

type Props = InjectedFormProps<IBannerForm, ComponentProps> & ComponentProps

const BannerForm: FC<Props> = (props) => {
	const [t] = useTranslation()
	const { handleSubmit, bannerID, loadingBannerData } = props

	const isLoading = useSelector((state: RootState) => state.banners.banner.isLoading)
	const isFormPristine = useSelector(isPristine(formName))
	const formValues = useSelector((state: RootState) => getFormValues(formName)(state)) as Partial<IBannerForm> | undefined
	const submitting = useSelector(isSubmitting(formName))

	const loading = submitting || isLoading

	const backgroundOptions = [
		{
			key: BACKGROUND_TYPES.COLOR,
			value: BACKGROUND_TYPES.COLOR,
			label: t('loc:Farba')
		},
		{
			key: BACKGROUND_TYPES.IMAGE,
			value: BACKGROUND_TYPES.IMAGE,
			label: t('loc:Obrázok')
		}
	]

	return (
		<Spin spinning={loading || loadingBannerData}>
			<Form layout={'vertical'} className={'form'} onSubmitCapture={handleSubmit}>
				<div className={'text-notino-black font-semibold text-base'}>{t('loc:Popredie')}</div>
				<Row>
					<Col span={12}>
						<Field
							component={ImgUploadField}
							name='desktopImage'
							label={
								<div>
									{t('loc:Web')} <span className={'text-notino-grayDark'}>(1035px x 340px)</span>
								</div>
							}
							signUrl={URL_UPLOAD_FILE}
							category={UPLOAD_IMG_CATEGORIES.CMS_BANNER}
							required
							maxFileSize={UPLOAD.BANNER_CMS_IMAGE.MAX_FILE_SIZE}
							accept={UPLOAD.BANNER_CMS_IMAGE.ACCEPT}
							tooltip={getUploadLimitsMessage(UPLOAD.BANNER_CMS_IMAGE.ACCEPT, UPLOAD.BANNER_CMS_IMAGE.MAX_FILE_SIZE)}
							maxCount={UPLOAD.BANNER_CMS_IMAGE.MAX_COUNT}
						/>
					</Col>
					<Col span={12}>
						<Field
							component={ImgUploadField}
							name='mobileImage'
							label={
								<div>
									{t('loc:Mobil')} <span className={'text-notino-grayDark'}>(768px x 340px)</span>
								</div>
							}
							signUrl={URL_UPLOAD_FILE}
							category={UPLOAD_IMG_CATEGORIES.CMS_BANNER}
							required
							maxFileSize={UPLOAD.BANNER_CMS_IMAGE.MAX_FILE_SIZE}
							accept={UPLOAD.BANNER_CMS_IMAGE.ACCEPT}
							tooltip={getUploadLimitsMessage(UPLOAD.BANNER_CMS_IMAGE.ACCEPT, UPLOAD.BANNER_CMS_IMAGE.MAX_FILE_SIZE)}
							maxCount={UPLOAD.BANNER_CMS_IMAGE.MAX_COUNT}
						/>
					</Col>
				</Row>
				<div className={'text-notino-black font-semibold text-base'}>
					<span className={'text-xs text-notino-redLight'}>*</span> {t('loc:Pozadie')}
				</div>
				<Field className={'p-0 m-0'} component={RadioGroupField} name={'backgroundType'} options={backgroundOptions} />
				{formValues?.backgroundType === BACKGROUND_TYPES.COLOR ? (
					<Field disabledAlpha component={ColorPickerField} name={'backgroundColor'} size={'large'} required />
				) : (
					<Field
						component={ImgUploadField}
						name='backgroundImage'
						label={
							<div>
								{t('loc:Pozadie bannera')} <span className={'text-notino-grayDark'}>(2650px x 340px)</span>
							</div>
						}
						signUrl={URL_UPLOAD_FILE}
						category={UPLOAD_IMG_CATEGORIES.CMS_BANNER}
						maxFileSize={UPLOAD.BANNER_CMS_IMAGE.MAX_FILE_SIZE}
						accept={UPLOAD.BANNER_CMS_IMAGE.ACCEPT}
						tooltip={getUploadLimitsMessage(UPLOAD.BANNER_CMS_IMAGE.ACCEPT, UPLOAD.BANNER_CMS_IMAGE.MAX_FILE_SIZE)}
						maxCount={UPLOAD.BANNER_CMS_IMAGE.MAX_COUNT}
					/>
				)}
				<Field
					component={InputField}
					label={t('loc:Analytické tracking ID')}
					placeholder={t('loc:Zadajte analytické tracking ID')}
					name={'trackingID'}
					className={'pb-0'}
					size={'large'}
					required
				/>
				<div className={'text-notino-grayDark pb-4 pt-1 text-xs'}>{t('loc:Tracking id nemôže obsahovať medzery a malo by mať formát „name_name“')}</div>
				<Field component={InputField} label={t('loc:Cieľová adresa URL')} placeholder={t('loc:Zadajte url')} name={'urlLink'} size={'large'} />
				<Field name={'infoText'} placeholder={t('loc:Zadajte info text')} label={t('loc:Info text')} component={TextareaField} />
				<Button className={'mt-4 noti-btn'} disabled={loading || isFormPristine} type={'primary'} block htmlType={'submit'}>
					{bannerID ? t('loc:Upraviť banner') : t('loc:Nahrať banner')}
				</Button>
			</Form>
		</Spin>
	)
}

const form = reduxForm<IBannerForm, ComponentProps>({
	form: formName,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	onSubmitFail: showErrorNotification,
	validate: validationBannerFn
})(BannerForm)

export default form
