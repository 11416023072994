import React from 'react'
import { Divider, Drawer, Spin } from 'antd'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

// types
import { RootState } from '../../reducers'

// utils
import { PERMISSION, RESERVATION_PAYMENT_METHOD, RESERVATION_STATE } from '../../utils/enums'
import { getStateSpecificProps, ReservationDetailSidebarProps } from './reservationPopoverUtils'
import { getDateTime } from '../../utils/helper'
import { checkPermissions } from '../../utils/Permissions'

// components
import HeaderContent from './components/HeaderContent'
import MainContent from './components/MainContent'

const ReservationDetailSidebar = (props: ReservationDetailSidebarProps) => {
	const {
		calendarEvent,
		isOpen,
		setIsOpen,
		headerButtons,
		sidebarProps,
		loading = false,
		fetchEventDetailAfterStateUpdate = true,
		afterOpenChange,
		handleUpdateReservationState,
		salonData
	} = props

	const handleCloseSidebar = () => {
		setIsOpen(false)
	}

	const authUser = useSelector((state: RootState) => state.user.authUser)
	const isNotinoUser = checkPermissions(authUser.data?.uniqPermissions, [PERMISSION.NOTINO])

	const endDateTime = getDateTime(calendarEvent.end.date, calendarEvent.end.time)
	const isPast = dayjs(endDateTime).isBefore(dayjs())

	const handleUpdateState = (state: RESERVATION_STATE, paymentMethod?: RESERVATION_PAYMENT_METHOD) => {
		handleUpdateReservationState({
			calendarEventID: calendarEvent.id,
			state,
			paymentMethod,
			eventData: { serviceId: calendarEvent.service?.id, customerId: calendarEvent.customer?.id },
			fetchEventDetailAfterStateUpdate,
			salonID: salonData?.id
		})
	}

	const { headerIcon, headerState, moreMenuItems, footerButtons } = getStateSpecificProps({ data: props, isNotinoUser, handleUpdateState, salonData, isSidebar: true })

	const hasFooter = !!footerButtons?.length

	return (
		<Drawer
			open={isOpen}
			className={'nc-reservation-detail-sidebar'}
			onClose={handleCloseSidebar}
			width={320}
			maskClosable
			closeIcon={null}
			closable={false}
			afterOpenChange={afterOpenChange}
			{...(sidebarProps || {})}
		>
			<Spin spinning={loading}>
				<div className='nc-popover-content flex flex-col h-full'>
					<header className={'flex items-center justify-between p-6 gap-2 flex-shrink-0'}>
						<HeaderContent
							calendarEvent={calendarEvent}
							headerIcon={headerIcon}
							headerState={headerState}
							headerButtons={headerButtons}
							moreMenuItems={moreMenuItems}
							onClose={handleCloseSidebar}
							isSidebar
						/>
					</header>
					<Divider className={'m-0'} />
					<main className={'px-6 overflow-y-auto relative flex-1'}>
						<MainContent {...props} headerButtons={headerButtons} isPast={isPast} isSidebar />
					</main>
					{hasFooter && <footer className={'flex flex-shrink-0 gap-4 pb-6 pt-4 px-6'}>{footerButtons}</footer>}
				</div>
			</Spin>
		</Drawer>
	)
}

export default ReservationDetailSidebar
