import React from 'react'
import colors from 'tailwindcss/colors'
import { useTranslation } from 'react-i18next'
import { Col, Divider, Row } from 'antd'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import cx from 'classnames'

// types
import { RootState } from '../../../reducers'

// utils
import Permissions from '../../../utils/Permissions'
import { PERMISSION, RESERVATION_SOURCE_TYPE, RESERVATION_STATE } from '../../../utils/enums'
import { getCountryPrefix, getCustomerName, getDateTime, getTimeText, getVisitorName, parseTimeFromMinutes } from '../../../utils/helper'
import { ReservationDetailProps } from '../reservationPopoverUtils'

// components
import UserAvatar from '../../AvatarComponents'
import Ellipsis from '../../../atoms/Ellipsis'
import BlacklistBadge from '../../../atoms/BlacklistBadge'

// assets
import MessageIcon from '../../../assets/icons/message-icon-16-thin.svg?react'
import NoteIcon from '../../../assets/icons/note-icon.svg?react'
import ExclamationMarkIcon from '../../../assets/icons/exclamation-mark-icon.svg?react'
import FullscreenIcon from '../../../assets/icons/fullscreen-icon.svg?react'
import ProfileIcon from '../../../assets/icons/profile-icon.svg?react'

type MainProps = ReservationDetailProps & { isPast: boolean; isSidebar?: boolean; onReservationCardClick?: () => void }

type ReservationInfoCardProps = MainProps

const ReservationInfoCard = (props: ReservationInfoCardProps) => {
	const { calendarEvent, isPast, color } = props
	const { service, serviceCategoryParameterValue, employee, reservationData, start, end } = calendarEvent

	const [t] = useTranslation()

	const startDateTime = getDateTime(start.date, start.time)
	const endDateTime = getDateTime(end.date, end.time)

	const monthName = dayjs(startDateTime).format('MMM')
	const dayNumber = dayjs(startDateTime).format('D')
	const isOnline = reservationData?.createSourceType === RESERVATION_SOURCE_TYPE.ONLINE
	const isApproved = reservationData?.state === RESERVATION_STATE.APPROVED

	return (
		<div className={'p-2 pl-0 flex items-center rounded relative min-h-16 overflow-hidden'} style={{ borderLeft: `4px solid ${color}` }}>
			<div className={'absolute inset-0 opacity-20'} style={{ background: color }} />
			{isPast && isApproved && (
				<div className='unclosed-reservation-badge'>
					<div className='square' />
					<ExclamationMarkIcon />
				</div>
			)}
			<Col flex={'32px'}>
				<div className={`text-center text-notino-black flex flex-col`}>
					<span className={'block text-sm leading-4'}>{dayNumber}</span>
					<span className={'text-xxs leading-3'}>{monthName}</span>
				</div>
			</Col>
			<Col flex={'auto'}>
				<Row className={'gap-2'} justify={'space-between'} align={'middle'} wrap={false}>
					<div className={'flex flex-col pl-3 gap-1'} style={{ borderLeft: `2px solid ${colors.black}` }}>
						<span className={'text-notino-grayDarker text-xxs leading-3'}>{`${getTimeText(startDateTime, endDateTime)} (${parseTimeFromMinutes(
							dayjs(endDateTime).diff(startDateTime, 'minutes')
						)})`}</span>
						{service?.name && service?.id ? (
							<span className={'block text-sm text-notino-black leading-4 break-all'}>{service.name}</span>
						) : (
							<span className={'block text-xs text-notino-grayDark break-all italic'}>{t('loc:Služba nie je zadaná')}</span>
						)}
						{serviceCategoryParameterValue?.categoryParameterValue.value && (
							<span className={'block text-xs text-notino-black leading-4 break-all'}>{serviceCategoryParameterValue.categoryParameterValue.value}</span>
						)}
						{isOnline && (
							<span className={'text-xxs leading-3'} style={{ color }}>
								{t('loc:Online')}
							</span>
						)}
					</div>
					<UserAvatar size={24} className={cx('employee-avatar shrink-0 mt-1', { 'is-deleted': employee?.deletedAt })} src={employee?.image?.resizedImages?.thumbnail} />
				</Row>
			</Col>
		</div>
	)
}

const MainContent = (props: MainProps) => {
	const { calendarEvent, isSidebar = false, headerButtons } = props
	const { customer, note, noteFromB2CCustomer, isVisitor, visitor } = calendarEvent
	const { onOpenInCalendar } = headerButtons || {}
	const [t] = useTranslation()
	const allCountries = useSelector((state: RootState) => state.config.config.data?.allCountries)
	const prefix = getCountryPrefix(allCountries, customer?.phonePrefixCountryCode)

	const notes = []
	if (noteFromB2CCustomer) {
		notes.push({ key: 'customer-note', text: noteFromB2CCustomer, internal: false })
	}
	if (note) {
		notes.push({
			key: 'internal-note',
			text: note,
			internal: true
		})
	}

	return (
		<>
			{customer && (
				<>
					<section className={'flex py-4 items-center'}>
						<Col flex={'32px'}>
							<UserAvatar size={24} className={'shrink-0'} src={customer.profileImage.resizedImages.thumbnail} />
						</Col>
						<Col flex={'auto'} className={'flex flex-col gap-2'}>
							<Row align={'top'} justify={'space-between'} wrap={false} className={'gap-2'}>
								<Row className={'flex-col gap-1'}>
									<span className={'text-sm leading-4 break-all'}>{getCustomerName(customer)}</span>
									<Permissions allowed={[PERMISSION.PARTNER_ADMIN, PERMISSION.CUSTOMER_READ_CONTACT_INFO, PERMISSION.NOTINO]}>
										{prefix && customer.phone && <span className={'text-xxs text-notino-grayDark leading-3'}>{`${prefix} ${customer.phone}`}</span>}
									</Permissions>
								</Row>
								<Row align={'middle'} className={'gap-2 h-8 flex-shrink-0'} wrap={false}>
									{customer.isBlacklisted && <BlacklistBadge />}
									<Permissions allowed={[PERMISSION.PARTNER_ADMIN, PERMISSION.CUSTOMER_READ_CONTACT_INFO, PERMISSION.NOTINO]}>
										{customer.email && (
											<a href={`mailto:${customer.email}`} className={'leading-3 relative'}>
												<span className={'absolute w-0 h-0 overflow-hidden opacity-0'}>{t('loc:Poslať email')}</span>
												<MessageIcon />
											</a>
										)}
									</Permissions>
								</Row>
							</Row>
							{customer.note && (
								<Ellipsis
									text={customer.note}
									className={'p-3 m-0 bg-notino-grayLighter text-xs leading-4 rounded-md rounded-t-none whitespace-pre-wrap break-all'}
								/>
							)}
						</Col>
					</section>
					<Divider className={'m-0'} />
				</>
			)}
			{isVisitor && (
				<>
					<section className={'flex py-4 items-center'}>
						<Col flex={'32px'}>
							<ProfileIcon className={'text-notino-grayDark rounded-full bg-notino-grayLighter p-1'} />
						</Col>
						<Col flex={'auto'} className={'flex flex-col gap-2'}>
							<Row className={'flex-col gap-1'}>
								<span className={'text-sm leading-4 break-all'}>{t('loc:Návštevník')}</span>
								<span className={'text-xs text-notino-grayDark leading-3'}>{getVisitorName(visitor?.firstName, visitor?.lastName)}</span>
							</Row>
						</Col>
					</section>
					<Divider className={'m-0'} />
				</>
			)}
			<section className={'my-2 rounded overflow-hidden'}>
				{isSidebar && onOpenInCalendar ? (
					<div
						role='button'
						tabIndex={0}
						className='block relative border-none bg-transparent outline-none overflow-hidden rounded group'
						onClick={() => {
							onOpenInCalendar(calendarEvent, 'reservation_card')
						}}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								onOpenInCalendar(calendarEvent, 'reservation_card')
							}
						}}
					>
						<div className='absolute inset-0 z-10 opacity-0 group-hover:opacity-100 group-focus:opacity-100 transition-opacity duration-200'>
							<div className='absolute inset-0 bg-notino-black opacity-60 z-10' />
							<div className='relative w-full h-full flex items-center justify-center gap-1 text-notino-white z-20'>
								{t('loc:Otvoriť v kalendári')}
								<FullscreenIcon className='w-4 h-4' />
							</div>
						</div>
						<ReservationInfoCard {...props} />
					</div>
				) : (
					<ReservationInfoCard {...props} />
				)}
			</section>
			{notes?.map((item) => {
				return (
					<React.Fragment key={item.key}>
						<Divider className={'m-0'} />
						<section className={'py-4'}>
							<div className={'note flex break-all text-sm leading-4'}>
								<Col flex={'32px'} className={'flex justify-center'}>
									<NoteIcon className={'shrink-0 text-notino-grayDark'} />
								</Col>
								<Col flex={'auto'}>
									<Row className={'gap-1'} align={'top'}>
										<Ellipsis text={item.text} className={'m-0 p-0 whitespace-pre-wrap flex-1'} />
										{item.internal && <span className={'nc-tag'}>{t('loc:Interná')}</span>}
									</Row>
								</Col>
							</div>
						</section>
					</React.Fragment>
				)
			})}
		</>
	)
}

export default MainContent
