import { z } from 'zod'
import { isNil } from 'lodash'

import { serializeValidationMessage, stringConstraint, twoCharsConstraint } from './baseSchema'
import { REVIEW_TYPE, VALIDATION_MAX_LENGTH } from '../utils/enums'

// eslint-disable-next-line import/prefer-default-export
export const reviewSchema = z
	.object({
		toxicityScoreFrom: z.number().finite().min(0).nullish(),
		toxicityScoreTo: z.number().finite().min(0).nullish(),
		salonCountryCode: twoCharsConstraint.nullish(),
		search: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255)
	})
	.superRefine((values, ctx) => {
		if (!isNil(values?.toxicityScoreFrom) && !isNil(values?.toxicityScoreTo) && (values?.toxicityScoreFrom || 0) > (values?.toxicityScoreTo || 0)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: serializeValidationMessage('loc:Toxicita OD musí byť menšia alebo rovnaká ako toxicita DO'),
				path: ['toxicityScoreFrom']
			})
		}
	})

export const salonReviewSchema = z.object({
	rating: z.number().finite().min(1).max(5).nullish(),
	search: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255),
	reviewType: z.nativeEnum(REVIEW_TYPE).nullish()
})

export const reviewResponseSchema = z.object({
	message: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255, true),
	editMode: z.boolean().nullish()
})
