import { Button, Collapse } from 'antd'
import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

// utils
import { getExpandIcon } from '../../../utils/helper'
import { LocalizedDateTime } from '../../../utils/intl'

// assets
import DragIcon from '../../../assets/icons/drag-icon.svg?react'
import EditIcon from '../../../assets/icons/edit-icon.svg?react'

// components
import DeleteButton from '../../../components/DeleteButton'

// types
import { GetUrls, RequestResponse } from '../../../types/interfaces'

type Props = {
	banner: RequestResponse<GetUrls['/api/b2b/admin/cms/banners/']>['banners'][0]
	onDelete: (id: string) => Promise<void>
	onEdit: (id: string) => Promise<void>
	disabled?: boolean
}

const BannerItem = ({ banner, onDelete, onEdit, disabled }: Props) => {
	const [t] = useTranslation()
	const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
		id: banner.id
	})

	const style: React.CSSProperties = {
		transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
		transition,
		...(isDragging ? { position: 'relative', zIndex: 9999 } : {})
	}

	const label = (
		<div className={'flex items-center gap-2'}>
			<div
				className={cx('text-notino-grayDark hover:cursor-move', { 'hover:cursor-move': !disabled, 'hover:cursor-not-allowed': disabled })}
				onClick={(e) => e.stopPropagation()}
				ref={setActivatorNodeRef}
				{...listeners}
			>
				<DragIcon width={12} height={12} />
			</div>
			<span className={'text-notino-black font-medium text-ellipsis overflow-hidden pr-2'}>{banner.trackingID}</span>
		</div>
	)

	const children = (
		<div
			style={{
				backgroundColor: banner.backgroundColor ? banner.backgroundColor : undefined,
				backgroundImage: banner.backgroundFile?.original ? `url(${banner.backgroundFile?.original})` : undefined,
				backgroundSize: 'cover',
				backgroundPosition: 'center'
			}}
			className={'rounded'}
		>
			<img className={'mx-auto w-9/12'} src={banner.desktopImage.original} alt='' />
		</div>
	)

	return (
		<div ref={setNodeRef} style={style} {...attributes}>
			<Collapse
				expandIconPosition={'right'}
				bordered={false}
				expandIcon={(panelProps) => getExpandIcon(!!panelProps.isActive, 16)}
				className={'noti-collapse-banner'}
				items={[
					{
						key: banner.id,
						label,
						children,
						extra: (
							<div className={'flex items-center gap-2'}>
								<LocalizedDateTime date={banner.updatedAt} timeStyle={null} ellipsis fallback={'-'} />
								<Button
									htmlType={'button'}
									size={'middle'}
									icon={<EditIcon className={'small-icon'} />}
									className={'ant-btn noti-btn'}
									onClick={(e) => {
										e.stopPropagation()
										onEdit(banner.id)
									}}
								/>
								<DeleteButton
									placement={'bottom'}
									entityName={t('loc:bannerovú pozíciu')}
									className={'bg-red-100'}
									onClick={(e) => e.stopPropagation()}
									onlyIcon
									onConfirm={(e) => {
										e?.stopPropagation()
										onDelete(banner.id)
									}}
									smallIcon
									size={'middle'}
								/>
							</div>
						)
					}
				]}
			/>
		</div>
	)
}

export default BannerItem
