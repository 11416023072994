import { z } from 'zod'
import { imageConstraint, serializeValidationMessage, stringConstraint } from './baseSchema'
import { BACKGROUND_TYPES, VALIDATION_MAX_LENGTH } from '../utils/enums'
import { colorRegex, trackingIdRegex } from '../utils/regex'

// eslint-disable-next-line import/prefer-default-export
export const bannerSchema = z
	.object({
		backgroundColor: z.string().regex(colorRegex).optional(),
		backgroundImage: imageConstraint.array().max(1).nullish(),
		backgroundType: z.nativeEnum(BACKGROUND_TYPES).nullish().catch(BACKGROUND_TYPES.COLOR)
	})
	.superRefine((data, ctx) => {
		if (data.backgroundType === BACKGROUND_TYPES.COLOR) {
			if (!data.backgroundColor) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: serializeValidationMessage('loc:Toto pole je povinné'),
					path: ['backgroundColor']
				})
			}
		} else if (data.backgroundType === BACKGROUND_TYPES.IMAGE) {
			if (!data.backgroundImage) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: serializeValidationMessage('loc:Toto pole je povinné'),
					path: ['backgroundImage']
				})
			}
		}
		return true
	})
	.and(
		z.object({
			infoText: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255, false),
			urlLink: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255, false),
			trackingID: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_255, true).regex(trackingIdRegex),
			desktopImage: imageConstraint.array().max(1).min(1),
			mobileImage: imageConstraint.array().max(1).min(1)
		})
	)
