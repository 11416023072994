import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Collapse, Modal, Popconfirm, Row, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { map } from 'lodash'
import { initialize, isPristine } from 'redux-form'
import { UniqueIdentifier } from '@dnd-kit/core'
import { compose } from 'redux'

// components
import Breadcrumbs from '../../components/Breadcrumbs'
import TabsComponent from '../../components/TabsComponent'
import BannersList from './components/BannersList'
import BannerForm from './components/BannerForm'

// types
import { IBannerForm, IBreadcrumbs } from '../../types/interfaces'
import { RootState } from '../../reducers'

// schemas
import { bannersURLQueryParamsSchema } from '../../schemas/queryParams'

// hooks
import useQueryParams from '../../hooks/useQueryParamsZod'

// utils
import { BACKGROUND_TYPES, COUNTRY_CODES, DEFAULT_COLOR_PICKER_VALUE, FORM, LANGUAGE, LOCALES, MSG_TYPE, PERMISSION } from '../../utils/enums'
import { getExpandIcon, showNotifications } from '../../utils/helper'
import { deleteReq, patchReq, postReq } from '../../utils/request'
import { withPermissions } from '../../utils/Permissions'

// actions
import { getBanner, getBanners } from '../../reducers/banners/bannersActions'

// assets
import UploadIcon from '../../assets/icons/upload-icon.svg?react'
import CloseIcon from '../../assets/icons/close-icon-modal.svg?react'

enum COLLAPSE_KEYS {
	WSK_CAROUSEL = 'WSK_CAROUSEL'
}

const BannersPage = () => {
	const [t] = useTranslation()
	const countries = useSelector((state: RootState) => state.config.config).optionsRolloutCountries
	const dispatch = useDispatch()
	const [visibleModal, setVisibleModal] = useState<{ visible: boolean; id?: string }>({ visible: false, id: undefined })
	const [loadingBannerData, setLoadingBannerData] = useState(false)
	const banners = useSelector((state: RootState) => state.banners.banners)
	const isFormPristine = useSelector(isPristine(FORM.BANNER_FORM))

	const [query, setQuery] = useQueryParams(bannersURLQueryParamsSchema, {
		page: 1,
		limit: 100,
		countryCode: COUNTRY_CODES.SK
	})
	const currentCountry = countries.find((c) => c.key === query.countryCode)

	const fetchBanners = useCallback(async () => {
		await dispatch(getBanners(query))
	}, [dispatch, query])

	useEffect(() => {
		fetchBanners()
	}, [fetchBanners])

	const breadcrumbs: IBreadcrumbs = {
		items: [
			{
				name: t('loc:Bannery')
			}
		]
	}

	const countryItems = map(countries, (country) => {
		return {
			key: String(country.key),
			label: (
				<div className={'inline-flex items-center gap-1'}>
					{country?.extra?.image && <img src={country.extra.image} className={'flex-shrink-0'} alt={''} />}
					{country?.key}
				</div>
			)
		}
	})

	const onDelete = async (bannerID: string) => {
		try {
			await deleteReq('/api/b2b/admin/cms/banners/{bannerID}', { params: { path: { bannerID } }, reqBody: {} })
			dispatch(getBanners(query))
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	const onEdit = async (id: string) => {
		setLoadingBannerData(true)

		const { data } = await dispatch(getBanner(id))

		if (!data?.banner.id) {
			showNotifications([
				{
					type: MSG_TYPE.ERROR,
					message: t('loc:Nepodarilo sa načítať detail banneru - {{ bannerID }}', {
						bannerID: id
					})
				}
			])
		} else {
			const initData = {
				urlLink: data.banner.urlLink,
				trackingID: data.banner.trackingID,
				infoText: data.banner.infoText,
				backgroundColor: data.banner.backgroundColor,
				backgroundType: data.banner.backgroundFile ? BACKGROUND_TYPES.IMAGE : BACKGROUND_TYPES.COLOR,
				backgroundImage: data.banner.backgroundFile
					? [
							{
								url: data.banner.backgroundFile.original,
								thumbUrl: data.banner.backgroundFile.resizedImages.thumbnail,
								uid: data.banner.backgroundFile.id,
								id: data.banner.backgroundFile.id
							}
						]
					: null,
				desktopImage: data.banner.desktopImage
					? [
							{
								url: data.banner.desktopImage.original,
								thumbUrl: data.banner.desktopImage.resizedImages.thumbnail,
								uid: data.banner.desktopImage.id,
								id: data.banner.desktopImage.id
							}
						]
					: null,
				mobileImage: data.banner.mobileImage
					? [
							{
								url: data.banner.mobileImage.original,
								thumbUrl: data.banner.mobileImage.resizedImages.thumbnail,
								uid: data.banner.mobileImage.id,
								id: data.banner.mobileImage.id
							}
						]
					: null
			}
			dispatch(initialize(FORM.BANNER_FORM, initData))
			setVisibleModal({ visible: true, id })
		}

		setLoadingBannerData(false)
	}

	const onTabChange = (selectedTabKey: string) => {
		setQuery({
			...query,
			countryCode: selectedTabKey as COUNTRY_CODES
		})
	}

	const handleReorder = async (bannerID: UniqueIdentifier, newIndex: number) => {
		try {
			await patchReq('/api/b2b/admin/cms/banners/{bannerID}/reorder', {
				params: {
					path: { bannerID: bannerID as string }
				},
				reqBody: {
					orderIndex: newIndex + 1
				}
			})
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		} finally {
			dispatch(getBanners(query))
		}
	}

	const handleSubmitBanner = async (values: IBannerForm) => {
		try {
			const reqBody = {
				countryCode: query.countryCode || LOCALES[LANGUAGE.SK].countryCode,
				backgroundColor: values.backgroundType === BACKGROUND_TYPES.COLOR ? values.backgroundColor : undefined,
				infoText: values.infoText,
				desktopImageID: values.desktopImage[0].id as string,
				mobileImageID: values.mobileImage[0].id as string,
				urlLink: values.urlLink,
				trackingID: values.trackingID,
				backgroundFileID: values.backgroundType === BACKGROUND_TYPES.IMAGE ? (values?.backgroundImage?.[0]?.id as string) : undefined
			}

			if (visibleModal.id) {
				await patchReq('/api/b2b/admin/cms/banners/{bannerID}', {
					params: { path: { bannerID: visibleModal.id } },
					reqBody
				})
			} else {
				await postReq('/api/b2b/admin/cms/banners/', {
					params: {},
					reqBody
				})
			}

			setVisibleModal({ visible: false })
			dispatch(getBanners(query))
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	const modal = (
		<Modal
			maskClosable={false}
			title={
				<div>
					{t('loc:Nahrať banner')}
					<div className={'flex items-center text-sm font-normal text-notino-grayDark gap-1'}>
						{t('loc:Banner bude zobrazený v')}
						<img src={currentCountry?.extra?.image} width={24} height={24} alt={currentCountry?.label} className={'flex-shrink-0'} />
						{currentCountry?.key}
					</div>
				</div>
			}
			open={visibleModal.visible}
			closeIcon={
				!isFormPristine ? (
					<Popconfirm
						title={t('loc:Chcete zahodiť vykonané zmeny?')}
						onConfirm={() => setVisibleModal({ visible: false })}
						okText={t('loc:Áno')}
						cancelText={t('loc:Nie')}
						okButtonProps={{
							type: 'default'
						}}
						cancelButtonProps={{
							type: 'primary'
						}}
					>
						<CloseIcon />
					</Popconfirm>
				) : (
					<CloseIcon onClick={() => setVisibleModal({ visible: false })} />
				)
			}
			destroyOnClose={true}
			footer={null}
			width={500}
		>
			<BannerForm loadingBannerData={loadingBannerData} bannerID={visibleModal.id} onSubmit={handleSubmitBanner} />
		</Modal>
	)

	const uploadButton = (
		<Button
			type={'primary'}
			className={'noti-btn'}
			icon={<UploadIcon className={'small-icon'} />}
			onClick={() => {
				dispatch(
					initialize(FORM.BANNER_FORM, {
						backgroundType: BACKGROUND_TYPES.COLOR,
						backgroundColor: DEFAULT_COLOR_PICKER_VALUE
					})
				)
				setVisibleModal({ visible: true })
			}}
		>
			{t('loc:Nahrať banner')}
		</Button>
	)

	return (
		<div>
			{modal}
			<Row>
				<Breadcrumbs breadcrumbs={breadcrumbs} />
			</Row>
			<div className={'mt-8 mb-4 text-notino-black heading-3'}>{t('loc:Bannery')}</div>
			<TabsComponent className={'box-tab'} activeKey={query.countryCode || LOCALES[LANGUAGE.SK].countryCode} onChange={onTabChange} items={countryItems} />
			<Spin spinning={banners.isLoading}>
				<Collapse
					bordered={false}
					defaultActiveKey={COLLAPSE_KEYS.WSK_CAROUSEL}
					expandIcon={(panelProps) => getExpandIcon(!!panelProps.isActive, 16)}
					className={'noti-collapse-wsk-carousel'}
					items={[
						{
							key: COLLAPSE_KEYS.WSK_CAROUSEL,
							label: <div className={'font-bold text-base text-notino-black'}>{t('loc:WSK carousel')}</div>,
							children:
								(banners?.data?.banners || [])?.length > 0 ? (
									<div>
										<BannersList onReorder={handleReorder} onDelete={onDelete} onEdit={onEdit} />
										<div className={'text-notino-grayDark text-xs'}>
											{t('loc:Bannery v karuseli sa zobrazia v rovnakom poradí, ako je definované v zozname.')}
										</div>
										<div className={'-ml-4 -mr-4 -mb-4 mt-4 bg-notino-grayLighter flex items-center justify-center p-4'}>{uploadButton}</div>
									</div>
								) : (
									<div className={'flex items-center flex-center-column gap-6'}>
										<span>{t('loc:Zatiaľ nie sú nahrané žiadne bannery')}</span>
										{uploadButton}
									</div>
								)
						}
					]}
				/>
			</Spin>
		</div>
	)
}

export default compose(withPermissions([PERMISSION.CMS_BANNER_READ]))(BannersPage)
