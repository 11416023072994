import React, { PropsWithChildren, Suspense, useEffect, useState } from 'react'
import { Layout } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import GlobalLoading from '../atoms/GlobalLoading'

// components
import LayoutSider from '../components/LayoutComponents/LayoutSider'
import LayoutHeader from '../components/LayoutComponents/LayoutHeader'
import WhatsNew from '../components/WhatsNew'
import LanguageInit from '../components/LanguageInit'
import MainLayoutSpin from './MainLayoutSpin'

// redux
import { RootState } from '../reducers'
import { getConfig } from '../reducers/config/configActions'

// hooks
import useInitializeSelectedSalon from '../hooks/useInitializeSelectedSalon'
import useNetworkStatus from '../hooks/useNetworkStatus'

type Props = {} & PropsWithChildren

const MainLayout = (props: Props) => {
	const { children } = props
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const [initialLanguageLoading, setInitialLanguageLoading] = useState(true)
	const { initialSalonLoading } = useInitializeSelectedSalon(initialLanguageLoading)

	const salonID = useSelector((state: RootState) => state.selectedSalon.selectedSalon.data?.id)

	useNetworkStatus()
	useEffect(() => {
		if (initialLanguageLoading) {
			return
		}

		dispatch(getConfig())
	}, [dispatch, initialLanguageLoading])

	return (
		<>
			<LanguageInit setLoadingLanguage={setInitialLanguageLoading} />
			{initialLanguageLoading || initialSalonLoading ? (
				<GlobalLoading />
			) : (
				<>
					<WhatsNew />
					<Layout className='min-h-screen noti-main-layout' hasSider>
						<LayoutSider {...props} salonID={salonID} parentPath={t('paths:salons/{{salonID}}', { salonID })} initialSalonLoading={initialSalonLoading} />
						<Layout>
							<LayoutHeader salonID={salonID} />
							<Suspense fallback={<MainLayoutSpin />}>{children}</Suspense>
						</Layout>
					</Layout>
				</>
			)}
		</>
	)
}

export default MainLayout
