import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { closestCenter, DndContext, DragEndEvent, UniqueIdentifier } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'

// reducers
import { RootState } from '../../../reducers'
import { reorderBanners } from '../../../reducers/banners/bannersActions'

// components
import BannerItem from './BannerItem'

type Props = {
	onDelete: (id: string) => Promise<void>
	onEdit: (id: string) => Promise<void>
	onReorder: (bannerID: UniqueIdentifier, newIndex: number) => Promise<void>
}

const BannersList = (props: Props) => {
	const banners = useSelector((state: RootState) => state.banners.banners)
	const dispatch = useDispatch()
	const { onEdit, onDelete, onReorder } = props
	const disabledDragging = banners.data?.banners.length === 1

	const onDragEnd = useCallback(
		async ({ active, over }: DragEndEvent) => {
			if (!active || !over || active.id === over.id) return
			const oldIndex = banners.data?.banners.findIndex((banner) => banner.id === active.id) || 0
			const newIndex = banners.data?.banners.findIndex((banner) => banner.id === over.id) || 0
			// Ensure valid indices
			if (oldIndex < 0 || newIndex < 0 || !banners?.data?.banners) return
			// Reorder the items using arrayMove from react-sortable-hoc
			const reorderedBanners = arrayMove(banners.data.banners, oldIndex, newIndex)
			dispatch(reorderBanners(reorderedBanners))
			// Reorder on BE
			onReorder(active.id, newIndex)
		},
		[banners, dispatch, onReorder]
	)

	return (
		<div className={'w-full overflow-x-auto overflow-y-hidden'}>
			<DndContext onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]} collisionDetection={closestCenter}>
				<SortableContext
					// banners ids
					items={(banners.data?.banners || []).map((banner) => banner.id)}
					disabled={disabledDragging}
					strategy={verticalListSortingStrategy}
				>
					{banners?.data?.banners.map((banner) => {
						return <BannerItem disabled={disabledDragging} key={banner.id} onDelete={onDelete} onEdit={onEdit} banner={banner} />
					})}
				</SortableContext>
			</DndContext>
		</div>
	)
}

export default BannersList
