// eslint-disable-next-line import/prefer-default-export
export enum BANNERS {
	BANNERS_LOAD_START = 'BANNERS_LOAD_START',
	BANNERS_LOAD_DONE = 'BANNERS_LOAD_DONE',
	BANNERS_LOAD_FAIL = 'BANNERS_LOAD_FAIL',

	BANNERS_REORDER = 'BANNERS_REORDER'
}

export enum BANNER {
	BANNER_LOAD_START = 'BANNER_LOAD_START',
	BANNER_LOAD_DONE = 'BANNER_LOAD_DONE',
	BANNER_LOAD_FAIL = 'BANNER_LOAD_FAIL'
}
