// types
import { IResetStore } from '../generalTypes'
import { GetUrls, ISearchable, RequestResponse } from '../../types/interfaces'
import { IGetBannersQueryParams } from '../../types/schemaTypes'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
import { BANNER, BANNERS } from './bannersTypes'

// utils
import { getReq } from '../../utils/request'

export type IBannersActions = IResetStore | IGetBanners | IGetBanner

interface IGetBanners {
	type: BANNERS
	payload: IBannersPayload
}

interface IGetBanner {
	type: BANNER
	payload: IBannerPayload
}

export interface IBannersPayload extends ISearchable<RequestResponse<GetUrls['/api/b2b/admin/cms/banners/']>> {}

export interface IBannerPayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/cms/banners/{bannerID}']> | null
}

export const getBanners =
	(queryParams?: IGetBannersQueryParams): ThunkResult<Promise<IBannersPayload>> =>
	async (dispatch) => {
		let payload = {} as IBannersPayload

		try {
			dispatch({ type: BANNERS.BANNERS_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/cms/banners/', {
				params: {
					query: {
						limit: queryParams?.limit,
						page: queryParams?.page,
						countryCode: queryParams?.countryCode
					}
				},
				reqBody: {}
			})
			payload = { data }
			dispatch({ type: BANNERS.BANNERS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: BANNERS.BANNERS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getBanner =
	(bannerID: string): ThunkResult<Promise<IBannerPayload>> =>
	async (dispatch) => {
		let payload = {} as IBannerPayload

		try {
			dispatch({ type: BANNER.BANNER_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/cms/banners/{bannerID}', {
				params: {
					path: { bannerID }
				},
				reqBody: {}
			})
			payload = { data }
			dispatch({ type: BANNER.BANNER_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: BANNER.BANNER_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const reorderBanners =
	(reorderedTableData: RequestResponse<GetUrls['/api/b2b/admin/cms/banners/']>['banners']): ThunkResult<void> =>
	async (dispatch) => {
		const payload = {
			data: { banners: reorderedTableData }
		}
		dispatch({ type: BANNERS.BANNERS_REORDER, payload })
	}
