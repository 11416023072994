/* eslint-disable import/no-cycle */
import { RESET_STORE } from '../generalTypes'
import { ILoadingAndFailure } from '../../types/interfaces'
import { IBannerPayload, IBannersActions, IBannersPayload } from './bannersActions'
import { BANNERS, BANNER } from './bannersTypes'

export const initState = {
	banners: {
		data: null,
		isLoading: false,
		isFailure: false
	} as IBannersPayload & ILoadingAndFailure,
	banner: {
		data: null,
		isLoading: false,
		isFailure: false
	} as IBannerPayload & ILoadingAndFailure
}

// eslint-disable-next-line default-param-last
export default (state = initState, action: IBannersActions) => {
	switch (action.type) {
		// Banners
		case BANNERS.BANNERS_LOAD_START:
			return {
				...state,
				banners: {
					...state.banners,
					isLoading: true
				}
			}
		case BANNERS.BANNERS_LOAD_FAIL:
			return {
				...state,
				banners: {
					...initState.banners,
					isFailure: true
				}
			}
		case BANNERS.BANNERS_LOAD_DONE:
			return {
				...state,
				banners: {
					...initState.banners,
					data: action.payload.data
				}
			}
		// Reorder banners
		case BANNERS.BANNERS_REORDER:
			return {
				...state,
				banners: {
					...initState.banners,
					data: action.payload.data
				}
			}
		// Banner
		case BANNER.BANNER_LOAD_START:
			return {
				...state,
				banner: {
					...state.banner,
					isLoading: true
				}
			}
		case BANNER.BANNER_LOAD_FAIL:
			return {
				...state,
				banner: {
					...initState.banner,
					isFailure: true
				}
			}
		case BANNER.BANNER_LOAD_DONE:
			return {
				...state,
				banner: {
					...initState.banner,
					data: action.payload.data
				}
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
