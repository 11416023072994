import { z } from 'zod'
import { dateConstraint, selectObjConstraint, serializeValidationMessage, stringConstraint } from './baseSchema'
import { RESERVATION_FEEDBACK_STATE, VALIDATION_MAX_LENGTH } from '../utils/enums'
import { timeRegex } from '../utils/regex'

export const importedReservationSchema = z.object({
	date: dateConstraint,
	timeFrom: z.string().regex(timeRegex),
	timeTo: z.string().regex(timeRegex),
	note: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_1500)
})

export const reservationsSchema = z
	.object({
		isVisitor: z.boolean().nullish(),
		visitor: z
			.object({
				firstName: z.string().nullish(),
				lastName: z.string().nullish()
			})
			.optional()
	})
	.superRefine((values, ctx) => {
		if (values.isVisitor) {
			if (!values.visitor?.firstName) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: serializeValidationMessage('loc:Toto pole je povinné'),
					path: ['visitor', 'firstName']
				})
			}
			if (!values.visitor?.lastName) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: serializeValidationMessage('loc:Toto pole je povinné'),
					path: ['visitor', 'lastName']
				})
			}
		}
	})
	.and(
		z.object({
			employee: selectObjConstraint,
			customer: selectObjConstraint,
			service: selectObjConstraint,
			date: dateConstraint,
			timeFrom: z.string().regex(timeRegex),
			timeTo: z.string().regex(timeRegex),
			note: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_1500)
		})
	)

export const cancelReservationSchema = z.object({
	text: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_160),
	quickFeedbackAnswers: z.nativeEnum(RESERVATION_FEEDBACK_STATE)
})
