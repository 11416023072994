import React from 'react'
import { ColorPicker, ColorPickerProps, Form } from 'antd'
import cx from 'classnames'
import { WrappedFieldProps } from 'redux-form'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'
import { Color } from 'antd/es/color-picker'

const { Item } = Form

type Props = WrappedFieldProps &
	ColorPickerProps &
	FormItemLabelProps & {
		hideHelp?: boolean
		required?: boolean
	}

const ColorPickerField = (props: Props) => {
	const {
		input,
		size,
		label,
		disabled,
		style,
		hideHelp,
		required,
		disabledAlpha,
		meta: { error, touched },
		className
	} = props

	const onChange = (color: Color) => {
		const hexColor = color.toHexString()
		input.onChange(hexColor)
	}
	return (
		<Item
			label={label}
			required={required}
			style={style}
			className={cx(className, { 'form-item-disabled': disabled })}
			help={hideHelp ? undefined : touched && error}
			validateStatus={error && touched ? 'error' : undefined}
		>
			<ColorPicker
				disabledAlpha={disabledAlpha}
				disabled={disabled}
				value={input.value}
				className={'border border-notino-grayLight rounded'}
				size={size}
				showText
				onChangeComplete={onChange}
			/>
		</Item>
	)
}

export default React.memo(ColorPickerField)
