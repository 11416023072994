import { find, map } from 'lodash'

// types
import { IResetStore } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import { GetUrls, ISearchable, RequestResponse, RequestParams } from '../../types/interfaces'
import { IGetNotinoReservationsQueryParams, IGetSalonReservationsQueryParams } from '../../types/schemaTypes'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'

// enums
import {
	RESERVATIONS,
	NOTINO_RESERVATIONS,
	PENDING_RESERVATIONS_COUNT,
	UPCOMING_CUSTOMER_RESERVATIONS_COUNT,
	UPCOMING_EMPLOYEE_RESERVATIONS_COUNT
} from './paginatedReservationsTypes'
import {
	CALENDAR_EVENT_TYPE,
	RESERVATION_STATE,
	RESERVATION_SOURCE_TYPE,
	RESERVATION_PAYMENT_METHOD,
	RESERVATION_FROM_IMPORT,
	RESERVATIONS_DATE_TIME_FRAME,
	AFFIL_BONUS
} from '../../utils/enums'

// utils
import { getReq } from '../../utils/request'
import { getAssignedUserLabel, getCustomerName, getDateTime, getVisitorName, translateReservationSourceType } from '../../utils/helper'
import { formatDateTimeByLocale, formatDateTimeRangeByLocale } from '../../utils/intl'

// action types
export type ICalendarActions =
	| IResetStore
	| IGetSalonReservations
	| IGetNotinoReservations
	| IGetPendingReservationsCount
	| IGetUpcomingCustomerReservationsCount
	| IGetUpcomingEmployeeReservationsCount

export interface ISalonReservationsPayload extends ISearchable<RequestResponse<GetUrls['/api/b2b/admin/salons/{salonID}/calendar-events/paginated']>> {
	tableData: ISalonReservationsTableData[]
}

interface IGetSalonReservations {
	type: RESERVATIONS
	payload: ISalonReservationsPayload
}

export interface INotinoReservationsPayload extends ISearchable<RequestResponse<GetUrls['/api/b2b/admin/calendar-events/reservations/']>> {
	tableData: INotinoReservationsTableData[]
}

interface IGetNotinoReservations {
	type: NOTINO_RESERVATIONS
	payload: INotinoReservationsPayload
}
export interface IPendingReservationsCount {
	count: number
}

interface IGetPendingReservationsCount {
	type: PENDING_RESERVATIONS_COUNT
	payload: IPendingReservationsCount
}

export interface IUpcomingCustomerReservationsCount {
	count: number
}

interface IGetUpcomingCustomerReservationsCount {
	type: UPCOMING_CUSTOMER_RESERVATIONS_COUNT
	payload: IUpcomingCustomerReservationsCount
}

export interface IUpcomingEmployeeReservationsCount {
	count: number
}

interface IGetUpcomingEmployeeReservationsCount {
	type: UPCOMING_EMPLOYEE_RESERVATIONS_COUNT
	payload: IUpcomingEmployeeReservationsCount
}

export interface ISalonReservationsTableData {
	key: string
	id: string
	startDate: string
	time: string
	unformattedStartDate: string
	createdAt: string
	visitorName: string
	customer: {
		id?: string
		name: string
		thumbnail?: string
		originalImage?: string
	}
	service: {
		id?: string
		name: string
		thumbnail?: string
		originalImage?: string
	}
	employee: {
		id?: string
		name: string
		thumbnail?: string
		originalImage?: string
		deletedAt?: string
	}
	createSourceType: string
	state: RESERVATION_STATE
	paymentMethod: RESERVATION_PAYMENT_METHOD
	affilBonus?: {
		type: AFFIL_BONUS
		message?: string
	}
}

export interface INotinoReservationsTableData {
	key: string
	id: string
	salon: {
		name: string
		id: string
		deletedAt?: string
	}
	createdAt: string
	startDate: string
	time: string
	unformattedStartDate: string
	customer: {
		id: string
		name: string
		thumbnail?: string
		originalImage?: string
		deletedAt?: string
	}
	visitorName: string
	service: {
		id: string
		name: string
		thumbnail?: string
		originalImage?: string
		deletedAt?: string
	}
	employee: {
		name: string
		thumbnail?: string
		originalImage?: string
		deletedAt?: string
	}
	createSourceType: string
	state?: RESERVATION_STATE
	paymentMethod?: RESERVATION_PAYMENT_METHOD
	deletedAt?: string
}

type PaginatedReservationsQuery = NonNullable<RequestParams<GetUrls['/api/b2b/admin/salons/{salonID}/calendar-events/paginated']>['query']>

export const getPaginatedReservationsRequestQueryParams = (
	queryParams: Omit<IGetSalonReservationsQueryParams, 'salonID'>
): Omit<PaginatedReservationsQuery, 'eventTypes'> & { eventTypes: (CALENDAR_EVENT_TYPE.RESERVATION | typeof RESERVATION_FROM_IMPORT)[] } => {
	return {
		dateFrom: queryParams.dateFrom || undefined,
		dateTo: queryParams.dateTo || undefined,
		createdAtFrom: queryParams.createdAtFrom || undefined,
		createdAtTo: queryParams.createdAtTo || undefined,
		reservationStates: queryParams.reservationStates || undefined,
		employeeIDs: queryParams.employeeIDs || undefined,
		reservationPaymentMethods: queryParams.reservationPaymentMethods || undefined,
		reservationCreateSourceType: queryParams.reservationCreateSourceType || undefined,
		categoryIDs: queryParams.categoryIDs || undefined,
		limit: queryParams.limit,
		page: queryParams.page,
		search: queryParams.search,
		order: queryParams.order,
		customerIDs: queryParams.customerIDs || undefined,
		datetimeFrame: queryParams.datetimeFrame || undefined,
		eventTypes: [CALENDAR_EVENT_TYPE.RESERVATION, RESERVATION_FROM_IMPORT]
	}
}

export const getPaginatedReservations =
	(queryParams: IGetSalonReservationsQueryParams): ThunkResult<Promise<ISalonReservationsPayload>> =>
	async (dispatch) => {
		let payload = {} as ISalonReservationsPayload
		try {
			dispatch({ type: RESERVATIONS.RESERVATIONS_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/salons/{salonID}/calendar-events/paginated', {
				params: {
					path: { salonID: queryParams.salonID },
					query: getPaginatedReservationsRequestQueryParams(queryParams)
				},
				reqBody: {}
			})
			const tableData: ISalonReservationsTableData[] = map(data.calendarEvents, (event) => {
				const employee = find(data.employees, { id: event.employee.id })

				const item: ISalonReservationsTableData = {
					id: event.id,
					key: event.id,
					startDate: formatDateTimeByLocale(event.start.date, { timeStyle: null }) || '-',
					time: formatDateTimeRangeByLocale(getDateTime(event.start.date, event.start.time), getDateTime(event.end.date, event.end.time), {
						dateStyle: null
					}),
					visitorName: getVisitorName(event.visitor?.firstName, event.visitor?.lastName),
					unformattedStartDate: event.start.date,
					createdAt: formatDateTimeByLocale(event.createdAt) || '-',
					createSourceType: translateReservationSourceType(event.reservationData?.createSourceType as RESERVATION_SOURCE_TYPE).text,
					employee: {
						id: employee?.id,
						name: getAssignedUserLabel(employee),
						thumbnail: employee?.image?.resizedImages?.thumbnail,
						originalImage: employee?.image?.original,
						deletedAt: employee?.deletedAt
					},
					customer: {
						id: event.customer?.id,
						name: getCustomerName(event.customer),
						thumbnail: event.customer?.profileImage?.resizedImages?.thumbnail,
						originalImage: event.customer?.profileImage?.original
					},
					service: {
						id: event.service?.id,
						name: event.service?.name || '-',
						thumbnail: event.service?.icon?.resizedImages?.thumbnail,
						originalImage: event.service?.icon?.original
					},
					state: event.reservationData?.state as RESERVATION_STATE,
					paymentMethod: event.reservationData?.paymentMethod as RESERVATION_PAYMENT_METHOD,
					affilBonus: event.reservationData?.affilBonus
						? {
								type: event.reservationData.affilBonus as AFFIL_BONUS,
								message: event.reservationData.affilBonusMessage
							}
						: undefined
				}

				return item
			})
			payload = {
				data,
				tableData
			}
			dispatch({ type: RESERVATIONS.RESERVATIONS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: RESERVATIONS.RESERVATIONS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

type NotinoReservationsQuery = NonNullable<RequestParams<GetUrls['/api/b2b/admin/calendar-events/reservations/']>['query']>

export const getNotinoReservationsRequestQueryParams = (queryParams: IGetNotinoReservationsQueryParams): NotinoReservationsQuery => {
	return {
		search: queryParams.search,
		dateFrom: queryParams.dateFrom || undefined,
		dateTo: queryParams.dateTo || undefined,
		countryCode: queryParams.countryCode || undefined,
		createdAtFrom: queryParams.createdAtFrom || undefined,
		createdAtTo: queryParams.createdAtTo || undefined,
		reservationStates: queryParams.reservationStates || undefined,
		reservationPaymentMethods: queryParams.reservationPaymentMethods || undefined,
		reservationCreateSourceType: queryParams.reservationCreateSourceType || undefined,
		categoryFirstLevelIDs: queryParams.categoryFirstLevelIDs || undefined,
		limit: queryParams.limit,
		page: queryParams.page,
		order: queryParams.order
	}
}

export const getNotinoReservations =
	(queryParams: IGetNotinoReservationsQueryParams): ThunkResult<Promise<any>> =>
	async (dispatch) => {
		let payload = {} as INotinoReservationsPayload
		try {
			dispatch({ type: NOTINO_RESERVATIONS.NOTINO_RESERVATIONS_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/calendar-events/reservations/', {
				params: {
					query: getNotinoReservationsRequestQueryParams(queryParams)
				},
				reqBody: {}
			})
			const tableData: INotinoReservationsTableData[] = map(data.reservations, (reservation) => {
				const item: INotinoReservationsTableData = {
					key: reservation.id,
					id: reservation.id,
					salon: {
						id: reservation.salon.id || '',
						name: reservation.salon.name || '-',
						deletedAt: reservation.salon.deletedAt
					},
					createdAt: formatDateTimeByLocale(reservation.createdAt) || '-',
					startDate: formatDateTimeByLocale(reservation.start.date, { timeStyle: null }) || '-',
					time: formatDateTimeRangeByLocale(getDateTime(reservation.start.date, reservation.start.time), getDateTime(reservation.end.date, reservation.end.time), {
						dateStyle: null
					}),
					unformattedStartDate: reservation.start.date,
					visitorName: getVisitorName(reservation.visitor?.firstName, reservation.visitor?.lastName),
					customer: {
						id: reservation.customer?.id || '',
						name: getCustomerName(reservation.customer),
						thumbnail: reservation.customer?.profileImage?.resizedImages?.thumbnail,
						originalImage: reservation.customer?.profileImage?.original,
						deletedAt: reservation.customer?.deletedAt
					},
					service: {
						id: reservation.service?.id || '',
						name: reservation.service?.name || '-',
						thumbnail: reservation.service?.icon?.resizedImages?.thumbnail,
						originalImage: reservation.service?.icon?.original,
						deletedAt: reservation.service?.deletedAt
					},
					employee: {
						name: getAssignedUserLabel(reservation.employee),
						thumbnail: reservation.employee?.image?.resizedImages?.thumbnail,
						originalImage: reservation.employee?.image?.original,
						deletedAt: reservation.employee?.deletedAt
					},
					createSourceType: translateReservationSourceType(reservation.reservationData?.createSourceType as RESERVATION_SOURCE_TYPE).text,
					state: reservation.reservationData?.state as RESERVATION_STATE,
					paymentMethod: reservation.reservationData?.paymentMethod as RESERVATION_PAYMENT_METHOD,
					deletedAt: reservation.deletedAt ? formatDateTimeByLocale(reservation.deletedAt) : undefined
				}

				return item
			})
			payload = {
				data,
				tableData
			}
			dispatch({ type: NOTINO_RESERVATIONS.NOTINO_RESERVATIONS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: NOTINO_RESERVATIONS.NOTINO_RESERVATIONS_LOAD_FAIL })
		}
	}

export const getPendingReservationsCount =
	(salonID?: string): ThunkResult<Promise<IPendingReservationsCount>> =>
	async (dispatch) => {
		let payload = {} as IPendingReservationsCount
		try {
			dispatch({ type: PENDING_RESERVATIONS_COUNT.PENDING_RESERVATIONS_COUNT_LOAD_START })
			if (salonID) {
				const { data } = await getReq('/api/b2b/admin/salons/{salonID}/calendar-events/paginated', {
					params: {
						path: {
							salonID
						},
						query: {
							limit: 1,
							page: 1,
							eventTypes: [CALENDAR_EVENT_TYPE.RESERVATION],
							reservationStates: [RESERVATION_STATE.PENDING]
						}
					},
					reqBody: {}
				})

				payload = {
					count: data.pagination.totalCount
				}
			} else {
				payload = {
					count: 0
				}
			}
			dispatch({ type: PENDING_RESERVATIONS_COUNT.PENDING_RESERVATIONS_COUNT_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: PENDING_RESERVATIONS_COUNT.PENDING_RESERVATIONS_COUNT_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getUpcomingCustomerReservationsCount =
	(salonID: string, customerID: string): ThunkResult<Promise<IUpcomingCustomerReservationsCount>> =>
	async (dispatch) => {
		let payload = {} as IUpcomingCustomerReservationsCount
		try {
			dispatch({ type: UPCOMING_CUSTOMER_RESERVATIONS_COUNT.UPCOMING_CUSTOMER_RESERVATIONS_COUNT_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/salons/{salonID}/calendar-events/paginated', {
				params: {
					path: {
						salonID
					},
					query: {
						limit: 1,
						page: 1,
						eventTypes: [CALENDAR_EVENT_TYPE.RESERVATION, RESERVATION_FROM_IMPORT],
						datetimeFrame: RESERVATIONS_DATE_TIME_FRAME.UPCOMING,
						customerIDs: [customerID]
					}
				},
				reqBody: {}
			})

			payload = {
				count: data.pagination.totalCount
			}

			dispatch({ type: UPCOMING_CUSTOMER_RESERVATIONS_COUNT.UPCOMING_CUSTOMER_RESERVATIONS_COUNT_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: UPCOMING_CUSTOMER_RESERVATIONS_COUNT.UPCOMING_CUSTOMER_RESERVATIONS_COUNT_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const getUpcomingEmployeeReservationsCount =
	(salonID: string, employeeID: string): ThunkResult<Promise<IUpcomingCustomerReservationsCount>> =>
	async (dispatch) => {
		let payload = {} as IUpcomingCustomerReservationsCount
		try {
			dispatch({ type: UPCOMING_EMPLOYEE_RESERVATIONS_COUNT.UPCOMING_EMPLOYEE_RESERVATIONS_COUNT_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/salons/{salonID}/calendar-events/paginated', {
				params: {
					path: {
						salonID
					},
					query: {
						limit: 1,
						page: 1,
						eventTypes: [CALENDAR_EVENT_TYPE.RESERVATION, RESERVATION_FROM_IMPORT],
						datetimeFrame: RESERVATIONS_DATE_TIME_FRAME.UPCOMING,
						employeeIDs: [employeeID]
					}
				},
				reqBody: {}
			})

			payload = {
				count: data.pagination.totalCount
			}

			dispatch({ type: UPCOMING_EMPLOYEE_RESERVATIONS_COUNT.UPCOMING_EMPLOYEE_RESERVATIONS_COUNT_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: UPCOMING_EMPLOYEE_RESERVATIONS_COUNT.UPCOMING_EMPLOYEE_RESERVATIONS_COUNT_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}
